import React from "react";

const Skills = () => {
  return (
    <>
      <div style={mainBoxStyle}>
        <h1>Skills</h1>
      </div>
    </>
  );
};

export default Skills;
const mainBoxStyle = {
    // marginTop: "104px",
}