import React from "react";

const Projects = () => {
  return (
    <>
      <div style={mainBoxStyle}>
        <h1>Projects</h1>
      </div>
    </>
  );
};

export default Projects;
const mainBoxStyle = {
  // marginTop: "104px",
};
